import React, { useState } from "react";
import styles from "./CategorySelector.module.css";
import * as actions from "../../Redux/Actions";
import { useDispatch } from "react-redux";

import { specsInfo } from "../../Source/Rich-medias/richMediasinfo.js";

function CategorySelector({ updatePopupOpenHandler = undefined }) {
  const [selectedValue, setSelectedValue] = useState("");

  let dispatch = useDispatch();

  const handleSelectChange = (event) => {
    updatePopupOpenHandler && updatePopupOpenHandler(false);
    setSelectedValue(event.target.value);
    dispatch(actions.filterByCategory(event.target.value));
  };

  return (
    <>
      <select
        className={styles.catSelect}
        value={selectedValue}
        onChange={handleSelectChange}
      >
        <option value="">Rubros</option>
        <option value="allContent">Todos</option>
        <option value="gamingAndGambling">Gaming y Gambling</option>
        <option value="finance">Financiera</option>
        <option value="retail">Retail</option>
        <option value="automotive">Automotriz</option>
        <option value="beauty">Beauty</option>
        <option value="others">Otros</option>
      </select>
    </>
  );
}

export default CategorySelector;
