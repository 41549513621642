import React, { useEffect, useState } from "react";
import styles from "./LeftSide.module.css";
import RedirectButton from "../RedirectButton/RedirectButton";

import CategorySelector from "../CategorySelector/CategorySelector";

function LeftSide() {
  const [openedPopup, setOpenedPopup] = useState(true);

  const updatePopupOpenHandler = (childChange) => {
    setOpenedPopup(childChange);
  };

  return (
    <>
      <div className={styles.leftSide}>
        <CategorySelector updatePopupOpenHandler={updatePopupOpenHandler} />
        <RedirectButton />
      </div>
    </>
  );
}

export default LeftSide;
