import React, { useEffect, useState } from "react";
import styles from "./RedirectButton.module.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";

function RedirectButton() {
  const handleRedirect = () => {
    window.location.href = "https://369.ad/";
  };

  return (
    <>
      <button
        className={styles.redirectButton}
        onClick={() => {
          handleRedirect();
        }}
      >
        Ir a la web
        <FontAwesomeIcon
          className={styles.icon}
          icon={faArrowUpRightFromSquare}
        />
      </button>
    </>
  );
}

export default RedirectButton;
