import React, { useEffect, useState } from "react";
import styles from "./SpecsResponsive.module.css";
import { useSelector } from "react-redux";
import { specsInfo } from "../../Source/Rich-medias/richMediasinfo.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

function SpecsResponsive({ specIndex }) {
  let category = useSelector((state) => state.category);
  const [categorySpecs, setCategorySpecs] = useState(specsInfo.allContent);
  useEffect(() => {
    switch (category) {
      case "gamingAndGambling":
        setCategorySpecs(specsInfo.gamingAndGambling);
        break;
      case "finance":
        setCategorySpecs(specsInfo.finance);
        break;
      case "retail":
        setCategorySpecs(specsInfo.retail);
        break;
      case "automotive":
        setCategorySpecs(specsInfo.automotive);
        break;
      case "allContent":
        setCategorySpecs(specsInfo.allContent);
        break;
      default:
        setCategorySpecs(specsInfo.retail);
    }
  }, [category]);

  const [openedSpecs, setOpenedSpecs] = useState(false);
  return (
    <>
      <div className={styles.btnsContainer}>
        <button
          onClick={() => {
            setOpenedSpecs(true);
          }}
          className={styles.specsBtn}
        >
          Detalles
        </button>
        <button className={styles.callToAction}>
          {" "}
          <a className={styles.ctaLink} href="http://wa.me/+59891725733">
            ¡Contactanos!
          </a>
        </button>
      </div>
      <div
        style={{
          opacity: `${openedSpecs ? 1 : 0}`,
          "z-index": `${openedSpecs ? 1 : -1}`,
          top: `${openedSpecs ? "100px" : "10px"}`,
        }}
        className={styles.specs}
      >
        <h1>{categorySpecs[specIndex].title}</h1>
        {/* <h3>{specsInfo.categorySpecs[specIndex].title}</h3> */}
        <div className={styles.specContent}>
          <div className={styles.specsSection}>
            <p className={styles.specsSubtitle}>Tamaños:</p>
            <p className={styles.paragraph}>{categorySpecs[specIndex].sizes}</p>
          </div>
          <div className={styles.specsSection}>
            <p className={styles.specsSubtitle}>Observaciones:</p>
            <p className={styles.paragraph}>
              {categorySpecs[specIndex].observations}
            </p>
          </div>
          <p
            onClick={() => {
              setOpenedSpecs(false);
            }}
            className={styles.close}
          >
            X
          </p>
        </div>
      </div>
    </>
  );
}

export default SpecsResponsive;
