import ContentSlides from "./Components/Carrousel/ContentSlides";

import Header from "./Components/Header/Header";
import "./App.css";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

function App() {
  const [isBelow480, setIsBelow480] = useState(false);
  const [isBelow630, setIsBelow630] = useState(false);
  const [isOver768, setIsOver768] = useState(false);
  const [isBelow1024, setIsBelow1024] = useState(false);
  const [isOver1440, setIsOver1440] = useState(false);
  const [isOver1900, setIsOver1900] = useState(false);
  const [isOver2100, setIsOver2100] = useState(false);

  //REDUX

  let category = useSelector((state) => state.category);

  useEffect(() => {
    if (window.innerWidth <= 480) {
      setIsBelow480(true);
    } else if (window.innerWidth <= 630) {
      setIsBelow630(true);
    } else if (window.innerWidth >= 768 && window.innerWidth < 1024) {
      setIsOver768(true);
    } else if (window.innerWidth < 1024) {
      setIsBelow1024(true);
    } else if (window.innerWidth >= 1440 && window.innerWidth < 1900) {
      setIsOver1440(true);
    } else if (window.innerWidth >= 1900 && window.innerWidth < 2100) {
      setIsOver1900(true);
    } else if (window.innerWidth >= 2100) {
      setIsOver2100(true);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      //! A PARTIR DE 1024 PX LAS SPECS DEBERIAN QUEDAR OCULTAS Y PONER EL BOTON

      const isBelow480 = window.innerWidth <= 480;
      const isBelow630 = window.innerWidth <= 630;
      const isOver768 = window.innerWidth >= 768 && window.innerWidth < 1024;
      const isBelow1024 = window.innerWidth <= 1024;
      const isOver1440 = window.innerWidth >= 1440 && window.innerWidth < 1900;
      const isOver1900 = window.innerWidth >= 1900 && window.innerWidth < 2100;
      const isOver2100 = window.innerWidth >= 2100;

      setIsBelow480(isBelow480);
      setIsBelow630(isBelow630);
      setIsOver768(isOver768);
      setIsBelow1024(isBelow1024);
      setIsOver1440(isOver1440);
      setIsOver1900(isOver1900);
      setIsOver2100(isOver2100);
    };

    // Add the event listener when the component mounts
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
  }, [window.innerWidth, category]);

  return (
    <div className="App">
      <Header />
      <ContentSlides
        isBelow480={isBelow480}
        isBelow630={isBelow630}
        isOver768={isOver768}
      />
    </div>
  );
}

export default App;
