import React, { useEffect, useState } from "react";
import styles from "./Specs.module.css";
import { useSelector } from "react-redux";
import { specsInfo } from "../../Source/Rich-medias/richMediasinfo.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

function Specs({ specIndex }) {
  let category = useSelector((state) => state.category);
  const [categorySpecs, setCategorySpecs] = useState(specsInfo.allContent);
  useEffect(() => {
    switch (category) {
      case "gamingAndGambling":
        setCategorySpecs(specsInfo.gamingAndGambling);
        break;
      case "finance":
        setCategorySpecs(specsInfo.finance);
        break;
      case "retail":
        setCategorySpecs(specsInfo.retail);
        break;
      case "automotive":
        setCategorySpecs(specsInfo.automotive);
        break;
      case "beauty":
        setCategorySpecs(specsInfo.beauty);
        break;
      case "others":
        setCategorySpecs(specsInfo.others);
        break;
        case "allContent":
          setCategorySpecs(specsInfo.allContent);
          break;
        default:
          setCategorySpecs(specsInfo.retail);
    }
  }, [category]);

  return (
    <>
      <div className={styles.specs}>
        <h2>{categorySpecs[specIndex].title}</h2>
        <div className={styles.specContent}>
          <div id={styles.firstSection} className={styles.specsSection}>
            <p className={styles.specsSubtitle}>Tamaños:</p>
            <p className={styles.paragraph}>
              {" "}
              {categorySpecs[specIndex].sizes}
            </p>
          </div>
          <div className={styles.specsSection}>
            <p className={styles.specsSubtitle}>Observaciones:</p>
            <p className={styles.paragraph}>
              {categorySpecs[specIndex].observations}
            </p>
          </div>
        </div>
        <a className={styles.ctaLink} href="http://wa.me/+59891725733">
          <button className={styles.callToAction}>
            <FontAwesomeIcon icon={faWhatsapp} /> ¡Contactanos!
          </button>
        </a>
      </div>
    </>
  );
}

export default Specs;
