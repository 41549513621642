const initialState = {
  category: "",
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "FILTER_BY_CATEGORY":
      return {
        ...state,
        category: action.payload,
      };

    default:
      return { ...state };
  }
}
