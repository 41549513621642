import React from "react";
import style from "./Header.module.css";

import logo from "../../Source/Images/LogoBlue369.webp";

function Header() {
  return (
    <div className={style.container}>
      <img
        style={{ width: "40%", height: "auto" }}
        fetchpriority="high"
        className={style.logo}
        src={logo}
        alt=""
      />
      <p>
        Explorá nuestra galería de <strong>Rich Medias</strong>
      </p>
    </div>
  );
}

export default Header;
