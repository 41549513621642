import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styles from "./ContentSlides.module.css";
import {
  Navigation,
  Pagination,
  EffectCoverflow,
  EffectFlip,
} from "swiper/modules";
import { specsInfo } from "../../Source/Rich-medias/richMediasinfo";
import { Swiper, SwiperSlide } from "swiper/react";
import Specs from "../Specs/Specs";
import SpecsResponsive from "../SpecsResponsive/SpecsResponsive";
import LeftSide from "../LeftSide/LeftSide";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/effect-cube";
import "swiper/css/effect-coverflow";
import "swiper/css/effect-flip";

function ContentSlides({ isBelow480, isBelow630, isOver768 }) {
  //STATES
  const [activeSpec, setActiveSpec] = useState(0);

  let category = useSelector((state) => state.category);
  const [categorySpecs, setCategorySpecs] = useState(specsInfo.allContent);
  useEffect(() => {
    switch (category) {
      case "gamingAndGambling":
        setCategorySpecs(specsInfo.gamingAndGambling);
        break;
      case "finance":
        setCategorySpecs(specsInfo.finance);
        break;
      case "retail":
        setCategorySpecs(specsInfo.retail);
        break;
      case "automotive":
        setCategorySpecs(specsInfo.automotive);
        break;
      case "beauty":
        setCategorySpecs(specsInfo.beauty);
        break;
      case "others":
        setCategorySpecs(specsInfo.others);
        break;
      case "allContent":
        setCategorySpecs(specsInfo.allContent);
        break;
      default:
        setCategorySpecs(specsInfo.retail);
    }
  }, [category]);

  return (
    <div className={styles.container}>
      <LeftSide />
      <Swiper
        onSlideChange={(swiper) => setActiveSpec(swiper.realIndex)}
        style={{
          "--swiper-pagination-color": "#00293f",
          "--swiper-pagination-bullet-inactive-color": "#00293f",
          "--swiper-pagination-bullet-inactive-opacity": "0.6",
          "--swiper-pagination-bullet-size": "13px",
          "--swiper-pagination-bullet-horizontal-gap": "4px",
          "--swiper-navigation-color": "#ffffff",
          "--swiper-slide-visible-height": "480px",
          "--swiper-slide-margin-right": "0px",
        }}
        initialSlide={0}
        breakpoints={{
          360: {
            spaceBetween: 300,
            slidesPerView: 1,
            coverflowEffect: {
              rotate: 0,
              stretch: 0,
              depth: 200,
              modifier: 1,
              slideShadows: false,
            },
          },
          480: {
            spaceBetween: -300,
            slidesPerView: 2,
            coverflowEffect: {
              rotate: 150,
              stretch: 0,
              depth: 150,
              modifier: 1,
              slideShadows: false,
            },
          },
          630: {
            spaceBetween: 0,
            slidesPerView: 2,
            coverflowEffect: {
              rotate: 150,
              stretch: 0,
              depth: 150,
              modifier: 1,
              slideShadows: false,
            },
          },
          768: {
            spaceBetween: -200,
            slidesPerView: 1.5,
            coverflowEffect: {
              rotate: 150,
              stretch: 0,
              depth: 250,
              modifier: 1,
              slideShadows: false,
            },
          },
          1024: {
            spaceBetween: 0,
            slidesPerView: 1,
            coverflowEffect: {
              rotate: 0,
              stretch: 0,
              depth: 20,
              modifier: 1,
              slideShadows: false,
            },
          },
          1440: {
            spaceBetween: -300,
            slidesPerView: 1.5,
            coverflowEffect: {
              rotate: 150,
              stretch: 0,
              depth: 150,
              modifier: 1,
              slideShadows: false,
            },
          },
          1920: {
            spaceBetween: -300,
            slidesPerView: 2,
            coverflowEffect: {
              rotate: 150,
              stretch: 0,
              depth: 150,
              modifier: 1,
              slideShadows: false,
            },
          },
        }}
        effect={"coverflow"}
        grabCursor={false}
        centeredSlides={true}
        slidesPerView={2}
        loop={true}
        pagination={{
          dynamicBullets: true,
        }}
        navigation={true}
        modules={[EffectCoverflow, EffectFlip, Pagination, Navigation]}
        className={styles.swiperContainer}
      >
        {categorySpecs.map((richMedia, index) => {
          return (
            <SwiperSlide>
              <div className={styles.slideContent}>
                <iframe
                  title="My IFrame"
                  src={richMedia.link}
                  width="320px"
                  height={richMedia.height ? richMedia.height : "480px"}
                  style={{
                    "box-shadow": `${
                      activeSpec === index
                        ? "0px -5px 10px rgba(0, 0, 0, 0.665)"
                        : "none"
                    }`,
                  }}
                ></iframe>
                <div
                  className={`${
                    activeSpec !== index ? styles.inactiveSlide : ""
                  }`}
                ></div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>

      {isBelow480 || isBelow630 || isOver768 ? (
        <SpecsResponsive specIndex={activeSpec} />
      ) : (
        <Specs specIndex={activeSpec} />
      )}
    </div>
  );
}

export default ContentSlides;

//AGREGAR UN LOADER MIENTRAS LOS IFRAMES SE CARGAN
//VER DE AGREGAR UN BUSCADOR PARA RICH MEDIAS ESPECIFICOS
